import styled from "styled-components";
import { rem, space, fontSize } from "../design-system";

const Tag = styled.span`
  display: inline-block;
  padding: ${rem(space(100))} ${rem(space(300))};
  margin-right: ${rem(space(100))};
  border-radius: 9999px;
  background-color: ${({ backgroundColor = "#edf2f7" }) =>
    backgroundColor} !important;
  color: ${({ color = "#4a5568" }) => color} !important;
  font-size: ${rem(fontSize(200))};
  font-weight: 600 !important;
  font-family: system-ui !important;
  text-decoration: none !important;
`;

export default Tag;
