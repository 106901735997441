import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { elevation, rem, fontSize, space } from "../design-system";
import P from "./P";
import Tag from "./Tag";

const Title = styled.h1`
  font-size: ${rem(fontSize(300))};
`;
const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -${rem(space(200))};
  }
`;
const PropertyItem = styled.li`
  & a {
    color: hsla(0, 0%, 0%, 0.8);
    text-decoration: none;
  }

  @media screen and (min-width: 768px) {
    width: 50%;
    padding: 0 ${rem(space(200))} ${rem(space(200))};
  }
`;
const Property = styled.article`
  ${elevation(200)};
  border-radius: 0.25rem;
  background: white;
  position: relative;
  overflow: hidden;
`;
const PropertyImage = styled.div`
  width: 100%;
  height: 0;
  padding-top: 50%;
  overflow: hidden;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;
const PropertyInfo = styled.div`
  position: relative;
  padding: ${rem(space(400))};
`;
const PropertyHeader = styled.header`
  margin: ${rem(space(200))} 0 ${rem(space(500))};
  font-family: system-ui;
`;
const PropertyTitle = styled.h1`
  margin: 0 0 ${rem(space(200))};
  font-size: ${rem(fontSize(600))};
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const PropertySummary = styled.div`
  height: ${rem(168)};
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;

  &::after {
    content: "...";
  }
`;

export default function Properties({ title, data }) {
  return (
    <>
      <Title>{title}</Title>
      {data.properties.edges.length ? (
        <List>
          {data.properties.edges.map(({ node }) => {
            const {
              title,
              image,
              rent,
              date,
              type,
            } = node.childMarkdownRemark.frontmatter;
            const { slug } = node.childMarkdownRemark.fields;
            const { html } = node.childMarkdownRemark;
            return (
              <PropertyItem key={node.id}>
                <Link to={slug} title="Read More">
                  <Property>
                    <PropertyImage src={image} />
                    <PropertyInfo>
                      <PropertyHeader>
                        <PropertyTitle>{title}</PropertyTitle>
                        <small>
                          Listed on <strong>{date}</strong>
                        </small>
                        &nbsp;
                        {!!rent && <Tag>${rent}</Tag>}
                        <Tag>{type}</Tag>
                      </PropertyHeader>
                      <PropertySummary
                        dangerouslySetInnerHTML={{
                          __html: html,
                        }}
                      ></PropertySummary>
                    </PropertyInfo>
                  </Property>
                </Link>
              </PropertyItem>
            );
          })}
        </List>
      ) : (
        <P>
          There are currently no available{" "}
          {data.propertyType.childMarkdownRemark.frontmatter.pluralLabel.toLowerCase()}
          .
        </P>
      )}
    </>
  );
}
