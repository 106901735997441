import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Properties from "../components/Properties";
import Tag from "../components/Tag";

function IndexPage() {
  const data = useStaticQuery(graphql`
    query IndexQuery {
      propertyTypes: allFile(
        filter: { sourceInstanceName: { eq: "propertyTypes" } }
      ) {
        edges {
          node {
            childMarkdownRemark {
              fields {
                slug
              }
              frontmatter {
                name
                pluralLabel
              }
            }
          }
        }
      }
      properties: allFile(
        filter: { sourceInstanceName: { eq: "properties" } }
      ) {
        edges {
          node {
            id
            childMarkdownRemark {
              fields {
                slug
              }
              html
              frontmatter {
                type
                title
                image
                rent
                date(formatString: "MMMM D, YYYY")
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Home" />
      <Properties
        title={
          <>
            All Available:{" "}
            {data.propertyTypes.edges.map(({ node }) => (
              <Link to={`/${node.childMarkdownRemark.fields.slug}`}>
                <Tag key={node.childMarkdownRemark.frontmatter.name}>
                  {node.childMarkdownRemark.frontmatter.name}
                </Tag>
              </Link>
            ))}
          </>
        }
        data={data}
      />
    </Layout>
  );
}

export default IndexPage;
